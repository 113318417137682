<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div>
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'caption')" type="text" placeholder="Search">
                    </div>
                </div>
                <div>
                    <div class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
                            <option :value="''">Select status</option>
                            <option :value="'invalid'">Invalid</option>
                            <option :value="'pending'">Pending</option>
                            <option :value="'valid'">Valid</option>
                            <option :value="''">All</option>
                        </select>
                    </div>
                </div>
                <div>
                    <div class="uk-inline">
                        <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="goToCreatePost">Create</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Status</th>
                            <th>Image</th>
                            <th>Caption</th>
                            <th>Image/Caption</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="posts.docs.length>0">
                        <tr v-for="(post, i) in posts.docs" :key="i">
                            <td>{{post.id}}</td>
                            <td><div class="label" :style="`background-color: ${status[post.status]}`">{{post.status}}</div></td>
                            <td><img :src="`${post.image}`" alt="" class="uk-margin-bottom" width="400"></td>
                            <td><p>{{post.caption.substring(0,250)}}</p></td>
                            <td>
                                <button style="width:100px; cursor:pointer; height:30px" class="uk-button-default" @click="showModalImage(post)">View</button>
                            </td>
                            <td>
                                <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-primary" @click="changeStatusPost(post.id, 'valid')">Verify</button>
                                <br/>
                                <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-secondary" @click="changeStatusPost(post.id, 'pending')">Pending</button>
                                <br/>
                                <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-danger" @click="changeStatusPost(post.id, 'invalid')">Reject</button>
                                <br/>
                                <button style="width:100px; cursor:pointer; height:30px" class="uk-button-default" @click="showEditModal(post)">Edit</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="posts.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="image-modal" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Image/Caption Detail</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <img :src="`${post_detail.image}`" alt="" class="uk-margin-bottom" width="600">
                    </div>
                    <br/>
                    <p>{{post_detail.caption}}</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="hideModal">Ok</button>
                </div>
            </div>
        </div>

        <div id="edit-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideEditModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Edit</h2>
                </div>
                <div class="uk-modal-body">
                    <label class="uk-form-label">Gender</label>
                    <div class="uk-width-1-1 uk-inline">
                        <multiselect
                            v-model="gender_value"
                            placeholder="Search or add gender"
                            label="name"
                            track-by="name"
                            :options="gender_options"
                            :multiple="true"
                            @tag="addGenderOption">
                        </multiselect>
                    </div>
                    <label class="uk-form-label">Education</label>
                    <div class="uk-width-1-1 uk-inline">
                        <multiselect
                            v-model="education_value"
                            placeholder="Search or add education"
                            label="name"
                            track-by="name"
                            :options="education_options"
                            :multiple="true"
                            @tag="addEducationOption">
                        </multiselect>
                    </div>
                    <label class="uk-form-label">Job Type</label>
                    <div class="uk-width-1-1 uk-inline">
                        <multiselect
                            v-model="job_type_value"
                            placeholder="Search or add job type"
                            label="name"
                            track-by="name"
                            :options="job_type_options"
                            :multiple="true"
                            @tag="addJobTypeOption">
                        </multiselect>
                    </div>
                    <label class="uk-form-label">Industry</label>
                    <div class="uk-width-1-1 uk-inline">
                        <multiselect
                            v-model="industry_value"
                            placeholder="Search or add industry"
                            label="name"
                            track-by="name"
                            :options="industry_options"
                            :multiple="true"
                            @tag="addIndustryOption">
                        </multiselect>
                    </div>
                    <br />
                    <br />
                    <label class="uk-form-label">Email</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.email"
                            placeholder="input email"
                        >
                    </div>
                    <label class="uk-form-label">Phone</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.phone"
                            placeholder="input phone"
                        >
                    </div>
                    <label class="uk-form-label">City</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.city"
                            placeholder="input city"
                        >
                    </div>
                    <label class="uk-form-label">Skills</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.skills"
                            placeholder="input skills"
                        >
                    </div>
                    <label class="uk-form-label">Company</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.company"
                            placeholder="input company"
                        >
                    </div>
                    <label class="uk-form-label">Title</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.title"
                            placeholder="input title"
                        >
                    </div>
                    <label class="uk-form-label">Caption</label>
                    <div class="uk-width-1-1 uk-inline">
                        <textarea
                            class="uk-input"
                            v-model="post_detail.caption"
                            placeholder="input caprion"
                        ></textarea>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="saveEditModal">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/utils/api';
import { INSTAJOB_API_POST } from '@/utils/api-url';
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data() {
        return {
            meta: {
                caption: '',
                limit: 100,
                page: 1,
                status: 'pending'
            },
            post_detail: {
                gender: [],
                education: [],
                job_type: [],
                email: '',
                phone: '',
                city: '',
                skills: '',
                company: '',
                title: '',
                caption: ''
            },
            education_value: [],
            education_options: [
                { name: 'sma/smk'},
                { name: 'diploma/d3'},
                { name: 'sarjana/s1'},
                { name: 'master/s2'},
                { name: 'apa saja'}
            ],
            gender_value: [],
            gender_options: [
                { name: 'laki-laki'},
                { name: 'perempuan'}
            ],
            job_type_value: [],
            job_type_options: [
                { name: 'full-time'},
                { name: 'contract'},
                { name: 'part-time'},
                { name: 'magang'},
                { name: 'freelance'}
            ],
            industry_value: [],
            industry_options: [
                { name: 'hotel'},
                { name: 'restoran/cafe'},
                { name: 'toko/retail'}
            ],
            status: {
                'invalid': '#ba1818',
                'pending': '#ff8000',
                'valid': '#00cc00'
            }
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    computed : {
        ...mapGetters({
            posts: 'post/posts',
        })
    },
    watch: {
        meta() {
            this.setPostMeta();
        },
    },
    mounted() {
        this.setPostMeta();
    },
    methods: {
        ...mapActions({
            getPost: 'post/getPost'
        }),
        setPostMeta() {
            this.getPost(this.meta);
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getPost(this.meta);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showModalImage(post) {
            this.post_detail = post;
            await window.UIkit.modal('#image-modal').show();
        },
        async hideModal() {
            await window.UIkit.modal('#image-modal').hide();
        },
        async showEditModal(post) {
            this.education_value = post.education.map(dat => {return {name: dat};});
            this.gender_value = post.gender.map(dat => {return {name: dat};});
            this.job_type_value = post.job_type.map(dat => {return {name: dat};});
            this.industry_value = post.industry.map(dat => {return {name: dat};});
            this.post_detail = post;
            await window.UIkit.modal('#edit-modal').show();
        },
        async hideEditModal() {
            await window.UIkit.modal('#edit-modal').hide();
        },
        async saveEditModal() {
            this.post_detail = {
                ...this.post_detail,
                gender: this.gender_value.map(dat => dat.name),
                education: this.education_value.map(dat => dat.name),
                job_type: this.job_type_value.map(dat => dat.name),
                industry: this.industry_value.map(dat => dat.name),
            };
            try {
                await api.apiPutAuth(INSTAJOB_API_POST.UPDATE(this.post_detail.id), this.post_detail);
                notificationSuccess('Post updated');
                this.getPost(this.meta);
                await window.UIkit.modal('#edit-modal').hide();
            } catch (err) {
                notificationDanger(err);
            }
        },
        async changeStatusPost(id, status) {
            try {
                await api.apiPatchAuth(INSTAJOB_API_POST.CHANGE_STATUS(id, status));
                notificationSuccess('Status changed !');
                this.getPost(this.meta);
            } catch (err) {
                notificationDanger(err);
            }
        },
        addEducationOption (newTag) {
            const tag = {
                name: newTag
            };
            this.education_options.push(tag);
            this.education_value.push(tag);
        },
        addGenderOption (newTag) {
            const tag = {
                name: newTag
            };
            this.gender_options.push(tag);
            this.gender_value.push(tag);
        },
        addJobTypeOption (newTag) {
            const tag = {
                name: newTag
            };
            this.job_type_options.push(tag);
            this.job_type_value.push(tag);
        },
        addIndustryOption (newTag) {
            const tag = {
                name: newTag
            };
            this.industry_options.push(tag);
            this.industry_value.push(tag);
        },
        goToCreatePost() {
            this.$router.push('/admin/post/create');
        }
    }
};
</script>
